// .css-0 {
//   height: 100%;
// }

// .css-17o9exy {
//   text-align: center;
// }

// .no-gutters {
//   display: block !important;
// }

// .css-bc8wuj {
//   color: grey;
// }

// .css-1nhzo65-MuiContainer-root {
//   height: 100%;
// }

// .css-1fkyx8n-MuiGrid-root {
//   margin-top: 15rem;
// }

// .svg-inline--fa {
//   height: 15rem;
// }

// .css-1rssnjk-MuiAutocomplete-root {
//   width: 80%;
// }

// .diplomaIcon {
//   height: 5rem;
//   width: 5rem;
// }

// .handoutPage {
//   margin-right: 2rem;
//   margin-left: 2rem;
// }

// .handoutButton {
//   margin-bottom: auto !important;
//   margin-top: 0 !important;
//   width: 12rem;
//   height: 7rem;
//   display: block;

//   .svg-inline--fa {
//     height: 4rem;
//   }
// }

// .missingDiploma {
//   font-weight: bold;
//   .svg-inline--fa {
//     height: 1rem;
//   }
//   .svg-inline--fa.fa-xs {
//     vertical-align: -0.15rem;
//   }
// }

.handoutButton {
  height: 75%;
  width: 100%;
  display: block;
}
